import Cookies from 'js-cookie';
import staticImage from '@/assets/staticImages';

const user = {
	state: {
		imghost: 'https://cdn.dkycn.cn/melyshop',
		uploadUrl: 'https://file.qidian.shop/api/image/upload?PlatForm=melyshop&Path=ShopDecorate&Token=c1dd023d-1053-47c2-ac62-f9d31ee9c3c3',

		//装修模块列表
		moduleList: [
			{
				name: '商城公告',
				icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/gonggao.png',
				moduleType: 21
			},
			{
				name: '商品搜索',
				icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/sousuo.png',
				moduleType: 16
			},
			{
			    name: '文字标题',
			    icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/wenzibiaoti.png',
			    moduleType: 15
			},
			{
				name: '图片导航',
				icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/tupiandaohang.png',
				moduleType: 9
			},
			{
				name: '热区链接',
				icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/requ.png',
				moduleType: 12
			},
			{
			    name: '视频模块',
			    icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/shipin.png',
			    moduleType: 7
			},
			{
				name: '小程序直播',
				icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/zhibo.png',
				moduleType: 22
			},
			{
				name: '视频号直播',
				icon: 'https://cdn.dkycn.cn/melyshop/20220301150233.png',
				moduleType: 29
			},
			{
				name: '商品',
				icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/shangpin.png',
				moduleType: 2
			},
			{
			    name: '商城新品',
			    icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/xinpin.png',
			    moduleType: 23
			},
			{
				name: '猜你喜欢',
				icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/cainixihuan.png',
				moduleType: 6
			},
			{
				name: '优惠券',
				icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/youhuiquan.png',
				moduleType: 18
			},
			{
				name: '积分兑换',
				icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/jifenduihuan.png',
				moduleType: 20
			},
			{
			    name: '限时折扣',
			    icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/xianshizhekou.png',
			    moduleType: 17
			},
			{
			    name: '多人拼团',
			    icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/pintuan.png',
			    moduleType: 26
			},
			{
			    name: '助力领取',
			    icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/zhulilingqu.png',
			    moduleType: 27
			}, 
			{
			    name: '加好友',
			    icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/jiahaoyou.png',
			    moduleType: 28
			},
			
			{
				name: '公众号涨粉',
				icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/gongzhonghaozhangfen.png',
				moduleType: 30
		},
			
			{
			    name: '社群专享品',
			    icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/shequnzhuanxiang.png',
			    moduleType: 24
			},
			{
			    name: '社群绑定',
			    icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/shequnbangding.png',
			    moduleType: 25
			},
			{
			    name: '邀请分享员',
			    icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/yaoqingfenxiangyuan.png',
			    moduleType: 31
			},
			{
			    name: '多多商品专区',
			    icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/duoduoshangpinzhuanqu2.png',
			    moduleType: 32
			},
			{
			    name: '多多营销',
			    icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/duoduoyingxiao2.png',
			    moduleType: 33
			},
			{
			    name: '肉团团购',
			    icon: 'https://cdn.dkycn.cn/images/melyshop/zhuangxiu/routuantuangou2.png',
			    moduleType: 34
			},
		],

		//所有静态图片地址
		staticImage: staticImage,

		//功能页列表
		functionalPageList: [{
				id: 1,
				name: '首页'
			},{
				id: 2,
				name: '全部商品'
			},
			{
				id: 3,
				name: '个人中心'
			},
			{
				id: 4,
				name: '购物车'
			},
			{
				id: 5,
				name: '积分中心'
			},
			{
				id: 6,
				name: '日历签到'
			},
			{
				id: 7,
				name: '在线客服'
			},
			{
				id: 8,
				name: '订单列表'
			},
			{
				id: 9,
				name: '推手招募页'
			},
			{
				id: 10,
				name: '成为会员'
			},
			{
				id: 20,
				name: '门店列表',
			},
			{
				id: 11,
				name: '助力直播列表'
			},
			{
				id: 12,
				name: '扫码购'
			},
			{
				id: 13,
				name: '充值中心'
			},
			{
				id: 14,
				name: '群分享'
			},
			{
				id: 15,
				name: '内容营销列表'
			},
			{
				id: 16,
				name: '短视频带货',
				disposition:true
			},
			{
				id: 17,
				name: '积分码兑换积分'
			},
			{
				id: 18,
				name: 'AI舌诊',
				disposition:true
			},
			{
				id: 19,
				name: '企店即创主页',
				disposition:true
			},
		]
	},
	mutations: {
		
	}
};

export default user;
